import styled from 'styled-components'
import Image from 'next/image'

import ActionButton from '../ActionButton'

export const HomePageAccountCreationCard = styled.div`
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border: 1px solid ${({ theme }) => theme.colors.greyContour};
  display: flex;
  flex-direction: column-reverse;
  gap: 4rem;
  padding: 4rem;

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    flex-direction: row;
  }
`

export const LeftContent = styled.div`
  color: ${({ theme }) => theme.colors.pureWhite};

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    flex: 1;
  }
`

export const Img = styled(Image)`
  width: 100%;
`

export const RightContent = styled.div`
  margin: auto;

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    flex: 1;
  }
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.firaSans5XlBold};
  color: ${({ theme }) => theme.colors.blueSecondaryBis};
  margin: 0;
`

export const Description = styled.p`
  ${({ theme }) => theme.textStyles.montserratTextBase};
  color: ${({ theme }) => theme.colors.pureBlack};
`

export const Button = styled(ActionButton)`
  margin-top: 4.5rem;

  ${({ theme }) => theme.breakpoints.up('tablet')} {
    width: 26rem;
    margin-top: auto;
  }
`
